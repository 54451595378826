@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #383838;
  background-image: url("https://www.transparenttextures.com/patterns/escheresque.png");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #383838
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Condensed', sans-serif;
  color: #333
}

footer {
  font-family: 'Roboto Condensed', sans-serif;
  text-align: center;
  color: white;
  padding-bottom: 1rem;
}

.container {
  display: flexbox;
  background-color: rgb(230, 230, 230);
  border-radius: 1rem;
  margin: 1rem;
  padding: 0.75rem 0;
  box-shadow: 0 0.3rem #333;
}

  .container h1 {
    text-align: center;
    text-transform: uppercase;
  }

  .container .contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 1.5rem 1rem;
  }

  .container .contacts:last-child {
    padding-bottom: 2.5rem;
  }

    .contacts .avatar-image {
        width: 9rem;
        height: 9rem;
        border-radius: 50%;
    }

    .contacts .avatar {
      padding: 0 1rem;
    }

    .contacts .details {
      text-align: center;
      padding: 0 1rem;
    }

    .contacts h3 {
      text-transform: uppercase;
      margin: 0.5rem 0;
    }

    .contacts p {
      margin: 0.3rem 0;
    }

@media (min-width: 42rem) {
  .container {
    max-width: 640px;
    margin: 2rem auto;
  }

    .container .contacts {
      flex-direction: row;
    }

    .contacts h3 {
      margin-top: 0;
    }

    .contacts .details {
      width: 40%;
      text-align: left;
    }
}